
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Eine Vorlagen Komponente zum erstellen teilstandartisierter Dialog PopUps
 * Diesen kann ein Typ zugeordnet werden, der die Art des Dialogs festlegt
 * Somit verändern sich Farbe, Symbol und bestimmte weitere Eigenschaften
 * Die meisten Elemente können, jedoch noch angepasst werden
 */
@Component({})
export default class CustomDialog extends Vue {
  /**
   * Ob Dialog über einen Button geöffnet werden soll
   */
  @Prop({ type: Boolean, required: false, default: false })
  public button!: boolean

  /**
   * Label des Buttons
   */
  @Prop({ type: String, required: false, default: '' })
  public buttonLabel!: string

  /**
   * Titel des Dialogs
   */
  @Prop({ type: String, required: true })
  public title!: string

  /**
   * Text des Dialogs
   */
  @Prop({ type: String, required: false })
  public text!: string

  /**
   * Type des Dialogs
   * sccucess, error, warinung, info
   */
  @Prop({ type: String, required: false, default: 'info' })
  public type!: string

  /**
   * Bestätigen Button
   */
  @Prop({ type: String, required: false, default: 'OK' })
  public positive!: string

  /**
   * Abbrechen Button
   */
  @Prop({ type: String, required: false, default: 'Abbrechen' })
  public negative!: string

  /**
   * Ob eine Eingabe des Benutzers gefordert ist
   */
  @Prop({ type: Boolean, required: false, default: false })
  public prompt!: boolean

  /**
   * Ob Dialog ein Icon haben soll
   */
  @Prop({ type: Boolean, required: false, default: true })
  public icon!: boolean

  /**
   * Ob Dialog ein Custom Icon haben soll
   */
  @Prop({ type: String, required: false, default: '' })
  public customIcon!: string

  /**
   * Welche Werte der treeview ausgewählt sind
   */
  @Prop({ type: Boolean, required: false, default: null })
  public value!: boolean

  /**
   * Ob man aus dem Dialog rausklicken kann
   */
  @Prop({ type: Boolean, required: false, default: false })
  public modal!: boolean

  /**
   * Gibt eine CSS Klasse an, die an den Header angehangen wird.
   * Kann genutzt werden, um z.B. die Farbe des Headers zu beeinflussen.
   */
  @Prop({ type: String, required: false, default: '' })
  public headerClass!: string

  /**
   * Gibt an, ob der Inhalt scrollbar sein soll oder nicht.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public scrollable!: boolean

  /**
   * Ob Dialog offen ist
   */
  public isOpen = false

  /**
   * Setzt, in Abhängigkeit von der Property button den Wert von isOpen
   */
  public created(): void {
    this.isOpen = !this.button
  }

  /**
   * Gibt CSS-Klassen der Dialogs-Titelleiste zurück
   *
   * @returns Klassen für Titel
   */
  public get color(): string {
    if (this.headerClass !== '') {
      return 'headline ' + this.headerClass
    }

    return 'headline ' + this.type
  }

  /**
   * Getter ob Dialog offen ist
   *
   * @returns ob Dialog offen ist
   */
  public get open(): boolean {
    if (this.value === null) {
      return this.isOpen
    }
    return this.value
  }

  /**
   * Setter ob Dialog offen ist
   *
   * @param value - ob offen ist
   */
  public set open(value: boolean) {
    if (this.value !== null) {
      this.$emit('input', value)
    }
    this.isOpen = value
  }

  /**
   * Gibt das passende Icon nach Typ oder custom zurück
   *
   * @returns passende Icon nach Typ oder custom
   */
  public get headerIcon(): string {
    if (this.customIcon !== '') {
      return this.customIcon
    }
    if (this.type === 'warning') {
      return 'mdi-alert'
    }
    if (this.type === 'error') {
      return 'mdi-close-octagon'
    }
    if (this.type === 'info') {
      return 'mdi-information'
    }
    if (this.type === 'success') {
      return 'mdi-check-circle'
    }
    return ''
  }

  /**
   * Setzt den Zusatnd des Dialogs auf 'offen' und zeigt diesen somit an
   */
  public openDialog(): void {
    this.open = true
  }

  /**
   * Schließt den Dialog und emittet das Ergebnis
   *
   * @param result - positive oder negative Antwort des Benutzers
   */
  public closeDialog(result: boolean): void {
    this.open = false
    this.$emit('result', result)
  }
}
