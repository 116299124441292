
import { Component, Vue } from 'vue-property-decorator'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import { Column } from '@/interfaces/ContentLoader/column.interface'
import LazyContentLoader from '@/components/LazyContentLoader/LazyContentLoader.vue'
import ContentList from '@/components/ContentList/ContentList.vue'

type Source = 'audit' | 'picker-employees'

/**
 * View für LazyContentLoader
 */
@Component({
  components: {
    CustomDialog,
    LazyContentLoader,
    ContentList
  }
})
export default class SpielwieseList extends Vue {
  /**
   * Eigenschaft `compact-enabled` von LazyContentLoader
   */
  public compactEnabled = false

  /**
   * Liste mit allen erlaubten Datenquellen.
   */
  public allowSource: Source[] = ['audit', 'picker-employees']

  /**
   * Datenquelle von LazyContentLoader.
   */
  public source: Source = 'audit'

  /**
   * Gibt die Spalten für die Komponente `LazyContentLoader` zurück. Diese sind
   * abhängig von [[this.usedContent]].
   *
   * @returns - Liste mit den Spalten, die Angezeigt werden sollen.
   */
  public get columns(): Column[] {
    let columns: Column[] = []

    if (this.source === 'audit') {
      columns = [
        {
          listPosition: 'primary',
          listOnly: true,
          property: 'label',
          formatAs: 'string',
          formatted:
            '{labelPrefix} {label}{labelSubstance|| (??) }{labelSuffix}'
        },
        {
          listPosition: 'secondary',
          formatAs: 'string',
          property: 'categoryLabel',
          formatted: '{due} {categoryLabel||– ??}',
          listOnly: true
        },
        {
          label: 'auditList.column.due',
          property: 'due',
          formatAs: 'date',
          searchable: true,
          width: 110
        },
        {
          label: 'auditList.column.name',
          property: 'labelComplete',
          formatAs: 'string',
          formatted:
            '{labelPrefix} {label}{labelSubstance|| (??) }{labelSuffix}',
          searchable: true
        },
        {
          label: 'auditList.column.category',
          property: 'category',
          formatAs: 'string',
          formatted: '{categoryLabel}',
          searchable: true,
          maxWidth: 220,
          minWidth: 120
        },
        {
          label: 'auditList.column.category',
          property: 'categoryID',
          formatAs: 'string',
          filterAs: 'select',
          searchable: true,
          sortable: false,
          listOnly: true
        },
        {
          label: 'auditList.column.responsible',
          property: 'responsible',
          formatAs: 'string',
          formatted: '{responsibleFirstName} {responsibleLastName}',
          searchable: true,
          maxWidth: 220,
          minWidth: 120
        },
        {
          label: 'auditList.column.executor',
          property: 'executor',
          formatAs: 'string',
          formatted: '{executorFirstName} {executorLastName}',
          searchable: true,
          maxWidth: 220,
          minWidth: 120
        },
        {
          label: 'auditList.column.affected',
          property: 'affected',
          formatAs: 'string',
          formatted: '{affectedFirstName} {affectedLastName}',
          searchable: true,
          maxWidth: 220,
          minWidth: 120
        },
        {
          label: 'auditList.column.creator',
          property: 'createdBy',
          formatAs: 'string',
          formatted: '{createdByFirstName} {createdByLastName}',
          searchable: true,
          maxWidth: 220,
          minWidth: 120
        },
        {
          listPosition: 'actionIcon',
          description: 'auditList.column.progress',
          property: 'progress',
          formatAs: 'icon',
          sortable: false,
          width: 60
        },
        // Filter: Nur offene Audits anzeigen
        {
          label: 'auditList.filter.doneTrue',
          property: 'done',
          formatAs: 'icon',
          sortable: false,
          searchable: true,
          listOnly: true
        },
        // Filter: Nur abgeschlossene Audits anzeigen
        {
          label: 'auditList.filter.doneFalse',
          property: 'done-false',
          formatAs: 'icon',
          sortable: false,
          searchable: true,
          listOnly: true
        }
      ]
    } else if (this.source === 'picker-employees') {
      columns = [
        {
          label: 'test.employee',
          property: 'username',
          listPosition: 'primary',
          formatAs: 'string',
          formatted: '{firstName} {lastName}',
          searchable: true
        },
        {
          label: 'test.personnelNumber',
          property: 'personnelNumber',
          listPosition: 'secondary',
          formatAs: 'string',
          searchable: true,
          minWidth: 200
        }
      ]
    }

    return columns
  }

  /**
   * Gibt die Spalten für die Sortierung zurück.
   *
   * @returns - Property der Spalte, nach der Sortiert werden soll.
   */
  public get orderBy(): string {
    let orderBy = ''

    if (this.source === 'audit') {
      orderBy = 'labelComplete'
    } else if (this.source === 'picker-employees') {
      orderBy = 'username'
    }

    return orderBy
  }

  /**
   * Gibt die Argumente in der Browser-Konsole aus.
   *
   * @param args - Argumente die ausgegeben werden sollen.
   */
  public onEvent(...args: unknown[]): void {
    // eslint-disable-next-line no-console
    console.log(...args)
  }
}
